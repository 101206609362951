import React, { useState } from 'react';
import { View, TouchableOpacity, FlatList } from "react-native"
import type { PollCampaignProps, PollProps, PollResponseProps } from "../../types"
import { view_styles } from '../../constants/styles';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import PollSelectCard from './PollSelectCard';

type PollQuestionsSectionProps = {
    poll_campaign:PollCampaignProps,
    polls:PollProps[],
    poll_responses:PollResponseProps[],
    onSelectPoll:(p:PollProps) => void
}

const PollQuestionsSection = ({ poll_campaign, polls, poll_responses, onSelectPoll }:PollQuestionsSectionProps) => {
    const [ section_data, setSectionData ] = useState<{
        loading:boolean,
        expanded:boolean,
    }>({
        loading: false,
        expanded: true
    });
    const { expanded } = section_data;


    const renderPolls = (data:{item:PollProps, index:number}) => {
        const my_response = poll_responses.find(r => r.poll_id == data.item.poll_id)
        const response_option = data.item.poll_options?.find(po => po.poll_option_id == my_response?.poll_option_id);
        return (
            <View style={{ borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }}>
                <PollSelectCard
                    poll={data.item}
                    disabled={false}
                    selected={false}
                    poll_campaign={poll_campaign}
                    poll_response={my_response}
                    show_response={my_response ? true : false}
                    response_option={response_option}
                    onSelect={(p) => onSelectPoll(p)}
                />
            </View>
        )
    }

    return (
        <View style={{ ...view_styles.section }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setSectionData({ ...section_data, expanded: !expanded })}>
                <Icons.QuestionIcon size={18} color={Colors.brand.midnight} />
                <View style={{flex:1, marginLeft:10}}>
                    <Text theme='header'>Questions</Text>
                    <Text style={{ marginTop:3 }} theme='body'>Available questions can be found below</Text>
                </View>
                <Icons.ChevronIcon direction={expanded?'up':'down'} color={Colors.brand.midnight} size={8} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body }}>
                <FlatList
                    data={polls.sort((a,b) => a.priority - b.priority)}
                    renderItem={renderPolls}
                    keyExtractor={(item) => item.poll_id.toString()}
                />
            </View>
            :<></>}
        </View>
    )
}

export default PollQuestionsSection