import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import type { AthleteProps, ContestStatProps, EventProps } from '../../types';
import { MarketComponentApi, MarketComponentHelpers } from '../api';
import { Button, Icons, Text } from '../../Components';
import EventScoringCard from './EventScoringCard';
import { view_styles } from '../../constants/styles';
import Colors from '../../constants/colors';

type ContestStatsCardProps = {
    event:EventProps
}

const ContestStatsCard = ({ event }:ContestStatsCardProps) => {
    const [ show_stats, setShowStats ] = useState(false);
    const [ card_data, setData ] = useState<{
        loading:boolean,
        contest_stats:ContestStatProps[],
        athletes:AthleteProps[],
        active_stat_label?:string,
        active_team_id?:string,
    }>({
        loading:false,
        contest_stats: [],
        athletes: []
    })
    const { loading, contest_stats, athletes, active_stat_label, active_team_id } = card_data;

    const home_team = event.home
    const away_team = event.away
    const away_athletes = athletes.filter(a => a.team_id == away_team?.team_id);
    const home_athletes = athletes.filter(a => a.team_id == home_team?.team_id);

    const unique_stats = [ ...new Set(contest_stats.map(s => s.stat_label)) ]
    
    let filtered_stats = contest_stats.filter(s => s.stat_label == active_stat_label);
    if(active_team_id == away_team?.team_id){
        filtered_stats = filtered_stats.filter(s => away_athletes.map(a => a.athlete_id.toString()).includes(s.participant_id.toString()))
    }
    if(active_team_id == home_team?.team_id){
        filtered_stats = filtered_stats.filter(s => home_athletes.map(a => a.athlete_id.toString()).includes(s.participant_id.toString()))
    }


    useEffect(() => {
        console.log('gettin it!')
        getDataFromServer(event.event_id)
    },[event.event_id])

    const getDataFromServer = async(contest_id:string) => {
        const stats = await MarketComponentApi.getContestStatsByEvent(contest_id);
        const active_stats = [ ...new Set(stats.map(s => s.stat_label)) ]

        const athlete_ids = [ ...new Set(stats.filter(s => s.participant_type == 'athlete').map(s => s.participant_id)) ]
        const ps = await MarketComponentApi.getAthletesByIds(athlete_ids)
        setData({
            ...card_data,
            athletes: ps,
            contest_stats: stats,
            active_stat_label: active_stats[0],
            loading:false,
            active_team_id:event.away_team_id
        })
    }

    const renderAthletes = (data: { item:ContestStatProps, index:number }) => {
        const athlete = athletes.find(a => a.athlete_id == data.item.participant_id);
        if(!athlete){ return <></> }
        return (
            <View style={{ ...view_styles.body_row, padding:10, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>{athlete.first_name} {athlete.last_name} ({athlete.position})</Text>
                </View>
                <Text theme='header_2'>{data.item.value}</Text>
            </View>
        )
    }

    const renderStatOptions = (data: { item:string, index:number }) => {
        return (
            <TouchableOpacity 
                style={{  ...view_styles.body_row, padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} 
                onPress={() => {
                    setData({ ...card_data, active_stat_label: data.item })
                    setShowStats(false)
                }}>
                <Text theme='header_2'>{MarketComponentHelpers.prettyKey(data.item)}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ ...view_styles.section_body, padding:0 }}>
            <View style={{ padding:10 }}>
                <EventScoringCard
                    event={event}
                />
                <View style={{ ...view_styles.body_row, marginTop:10, padding:0, borderWidth:3, borderColor:Colors.shades.shade100, borderRadius:22 }}>
                    {away_team?
                    <TouchableOpacity 
                        style={{ 
                            flex:1, 
                            padding:10,
                            flexDirection:'row',
                            alignItems:'center',
                            backgroundColor:active_team_id == away_team.team_id ? Colors.brand.midnight : Colors.shades.white,
                            borderRadius:22
                        }}
                        onPress={() => setData({ ...card_data, active_team_id:away_team.team_id })}    
                    >
                        <View style={{ height:30, width:30, borderRadius:100, marginRight:10, backgroundColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}>
                            <Image
                                source={{ uri: away_team.image?.url }}
                                style={{ height:22, width:22, borderRadius:4 }}
                                resizeMode='cover'
                            />
                        </View>
                        <Text style={{ flex:1, marginRight:30 }} textAlign='center' size={12} color={active_team_id == away_team.team_id ? Colors.shades.white : Colors.brand.midnight} weight={active_team_id == away_team.team_id ? 'bold' : 'regular'}>{away_team.abbr}</Text>
                    </TouchableOpacity>
                    :<></>}
                    {home_team ?
                    <TouchableOpacity 
                    style={{ 
                        flex:1, 
                        padding:10,
                        flexDirection:'row',
                        alignItems:'center',
                        backgroundColor:active_team_id == home_team.team_id ? Colors.brand.midnight : Colors.shades.white,
                        borderRadius:22
                    }}
                    onPress={() => setData({ ...card_data, active_team_id:home_team.team_id })}    
                >
                    <View style={{ height:30, width:30, borderRadius:100, marginRight:10, backgroundColor:Colors.shades.white, justifyContent:'center', alignItems:'center' }}>
                        <Image
                            source={{ uri: home_team.image?.url }}
                            style={{ height:22, width:22, borderRadius:4 }}
                            resizeMode='cover'
                        />
                    </View>
                    <Text style={{ flex:1, marginRight:30 }} textAlign='center' size={12} color={active_team_id == home_team.team_id ? Colors.shades.white : Colors.brand.midnight} weight={active_team_id == home_team.team_id ? 'bold' : 'regular'}>{home_team.abbr}</Text>
                </TouchableOpacity>
                    :<></>}
                </View>  
                <View style={{ ...view_styles.body_row, borderTopRightRadius:8, borderTopLeftRadius:8, backgroundColor:Colors.shades.shade100, marginTop:5, marginBottom:5 }}>
                    <View style={{ flex:1 }}>
                        <Text theme='header'>Box Score</Text>
                    </View>
                    <TouchableOpacity style={{ padding:10, borderWidth:1, borderColor:Colors.brand.electric, borderRadius:8, flexDirection:'row', alignItems:'center' }}
                        onPress={() => setShowStats(!show_stats)}
                    >
                        <Text style={{ marginRight:8 }} size={12} color={Colors.brand.electric}>{active_stat_label ? MarketComponentHelpers.prettyKey(active_stat_label) : 'Select'}</Text>
                        <Icons.ChevronIcon size={8} direction={show_stats ? 'up' : 'down'} color={Colors.brand.electric} />
                    </TouchableOpacity>
                    {show_stats ?
                    <View style={{ position:'absolute', bottom:0, right:0, backgroundColor:Colors.shades.white, ...view_styles.float, minWidth:250, borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600, maxHeight: 250 }}>
                        <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                            <View style={{ flex:1 }}>
                                <Text theme='header'>Select Stat</Text>
                            </View>
                            <Button
                                title='X'
                                backgroundColor={Colors.shades.white}
                                borderWidth={1}
                                title_color={Colors.utility.error}
                                borderRadius={8}
                                borderColor={Colors.utility.error}
                                onPress={() => setShowStats(false)}
                            />
                        </View>
                        <FlatList
                            data={unique_stats}
                            renderItem={renderStatOptions}
                            keyExtractor={(item) => item}

                        />
                    </View>
                    :<></>}
                </View>
                {loading ?
                <ActivityIndicator style={{ padding:10, alignSelf:'center' }} color={Colors.brand.midnight} size='large' />
                :<></>}
                <FlatList
                    data={filtered_stats.sort((a,b) => b.value - a.value)}
                    renderItem={renderAthletes}
                    keyExtractor={(item) => `${item.stat}:${item.participant_id}`}
                />
            </View>
              
            
        </View>
    )
}

export default ContestStatsCard