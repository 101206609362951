import { TouchableOpacity, View } from "react-native"
import React, { useState } from "react"
import type { PollCampaignProps } from "../../types"
import { Icons, Text } from "../../Components"
import Colors from '../../constants/colors';
import { view_styles } from "../../constants/styles"
import CampaignLeaderboard from "./CampaignLeaderboard"

type CampaignResultProps = {
    poll_campaign:PollCampaignProps,
    onShowFullLeaderboard?:() => void
}

const CampaignResult = ({ poll_campaign, onShowFullLeaderboard } : CampaignResultProps) => {
    const [ expanded, setExpanded ] = useState(false);

    return (
        <View style={{ flex:1, ...view_styles.section }}>
            <TouchableOpacity style={{ ...view_styles.section_header }} onPress={() => setExpanded(!expanded)}>
                <Icons.PodiumIcon size={18} color={Colors.brand.midnight} />
                <View style={{flex:1, marginLeft:10}}>
                    <Text theme="header">Leaderboard</Text>
                    <Text style={{ marginTop:3 }} theme="body">Below are the leaderboard results!</Text>
                </View>
                <Icons.ChevronIcon size={8} color={Colors.brand.midnight} direction={expanded ? 'up' : 'down'} />
            </TouchableOpacity>
            {expanded ?
            <View style={{ ...view_styles.section_body, padding:0, backgroundColor:Colors.shades.shade100 }}>
                <CampaignLeaderboard
                    view_mode="short"
                    poll_campaign={poll_campaign}
                    onShowFullLeaderboard={onShowFullLeaderboard}
                />
            </View>
            :<></>}
        </View>
    )
}

export default CampaignResult