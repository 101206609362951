import { Image, View } from "react-native"
import { view_styles } from "../../constants/styles"
import { Text } from "../../Components"
import type { EventProps } from "../../types"
import React from "react"

type EventScoringCardProps = {
    event:EventProps
}
const EventScoringCard = ({ event }:EventScoringCardProps) => {
    const overtime_occurred = event.event_scoring && event.event_scoring.length > 4 ? true : false;
    if(!event.event_scoring){ return <></> }
    return (
        <View>
            <View style={view_styles.body_row}>
                <Image
                    source={{ uri: event.away?.image?.url }}
                    style={{ height:20, width:20, borderRadius:4, marginRight:5 }}
                    resizeMode="cover"
                />
                <Text style={{ flex:1 }} theme="body">{event.away?.abbr}</Text>
                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    {event.event_scoring.sort((a,b) => a.period - b.period).map(s => {
                        return (
                            <View style={{ flex:1 }}>
                                <Text theme={overtime_occurred && s.period == 4 ? 'error_header_2': 'header_2'}>{s.away_cumulative_score}</Text>
                            </View>
                        )
                    })}
                </View>
            </View>
            <View style={view_styles.body_row}>
                <Image
                    source={{ uri: event.home?.image?.url }}
                    style={{ height:20, width:20, borderRadius:4, marginRight:5 }}
                    resizeMode="cover"
                />
                <Text style={{ flex:1 }} theme="body">{event.home?.abbr}</Text>
                <View style={{ flex:1, flexDirection:'row', alignItems:'center' }}>
                    {event.event_scoring.map(s => {
                        return (
                            <View style={{ flex:1 }}>
                                <Text theme={overtime_occurred && s.period == 4 ? 'error_header_2': 'header_2'}>{s.home_cumulative_score}</Text>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
}

export default EventScoringCard