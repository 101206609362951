import { FlatList, Image, ScrollView, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import PollOptionCard from './PollOptionCard';
import ResponseTimer from './ResponseTimer';
import type { PollOptionProps, PollProps, PollResponseProps, PollSummaryProps } from '../../types';
import { PollApi } from '../api';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';

type PollCardProps = {
    poll_id:string,
    poll_response?:PollResponseProps,
    action_loading?:boolean,
    question_number?:number,
    view?:string,
    total_questions?:number,
    onResponse:(response:PollResponseProps) => void,
    onShowAuthenticate: () => void,
    onCancel:() => void,
    onSkip: () => void
}

const PollCard = ({ poll_id, poll_response, action_loading, onSkip, onCancel, onResponse }: PollCardProps) => {
    const [ poll_width, setPollWidth ] = useState(0);
    const [ poll_data, setPollData ] = useState<{
        poll?:PollProps,
        loading:boolean,
        selected_option?:PollOptionProps,
        poll_summaries:PollSummaryProps[],
        poll_options:PollOptionProps[],
    }>({
        loading:false,
        poll_summaries:[],
        poll_options:[]
    });
    const { loading, poll, poll_summaries, poll_options, selected_option } = poll_data;
    const correct_option = poll_options.find(o => o.result_ind == 'win');
    const responses = poll_summaries.reduce((a,b) => a + b.count, 0)
    useEffect(() => {
        getPollFromServer(poll_id)
    },[poll_id])
    
    const getPollFromServer = async(poll_id:string) => {
        setPollData({ ...poll_data, loading: true });
        const pos_resp = await PollApi.getPollById(poll_id)
        const my_response = pos_resp.poll_options.find(po => po.poll_option_id == poll_response?.poll_option_id);
        setPollData({
            ...poll_data,
            loading:false,
            poll:pos_resp.poll,
            poll_options:pos_resp.poll_options,
            poll_summaries: pos_resp.poll_summaries,
            selected_option: my_response
        })
    }

    const handleCancel = () => {
        setPollData({ ...poll_data, selected_option:undefined })
        onCancel()
    }

    const handleSkip = () => {
        //if(!is_authenticated){ return onShowAuthenticate() }
        //setPollData(DEFAULT_POLL_STATE)
        //setOptionSelected(undefined)
        //if(last_question && onSeeResults){ return onSeeResults() }
        onSkip()

    }

    const handleSubmitResponse = async() => {
        //if(!is_authenticated){ return onShowAuthenticate() }
        if(action_loading || poll_response || !selected_option || !poll){ return }
        let option = poll_options.find(po => po.poll_option_id == selected_option.poll_option_id)
        if(!option){ return }
        //We are good to log the response!
        let draft_response:PollResponseProps = {
            poll_id:poll.poll_id,
            poll_option_id:option.poll_option_id,
            stake: poll.minimum_stake,
            market_type: 'FREE',
            poll_response_id: '',
            player_id: '',
            winnings: 0,
            response_body: {},
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
        if(option.option_type == 'input'){
            draft_response.input_value = selected_option.option_value
        }
        onResponse(draft_response);
        //setPollData({ ...poll_data, selected_option:undefined })
    }

    const handleTimesUp = async() => {
        if(selected_option){
            return handleSubmitResponse()
        }
        if(!poll || poll.status != 'active'){ return }
        if(poll_response){ return }
        const draft_response:PollResponseProps = {
            poll_id:poll.poll_id,
            poll_option_id:'0',
            stake: poll.minimum_stake,
            market_type: 'FREE',
            poll_response_id: '',
            player_id: '',
            winnings: 0,
            timed_out: true,
            response_body: {},
            status: 'pending',
            create_datetime: '',
            last_update_datetime: ''
        }
        onResponse(draft_response)
    }

    const renderPollOptions = (data: { item:PollOptionProps, index:number }) => {
        if(!poll){ return <></ >}
        const poll_summary = poll_summaries.find(ps => ps.poll_option_id == data.item.poll_option_id);
        const draft_selected = selected_option?.poll_option_id == data.item.poll_option_id ? true : false;
        return (
            <View style={{ margin:5, flex:1 }}>
                <PollOptionCard
                    poll_summary={poll_summary}
                    draft_selected={draft_selected}
                    poll_response={poll_response}
                    poll_option={data.item}
                    show_summary={(poll_response) && poll.show_responses ? true : false}
                    onSelect={(poll_option) => {
                        if(poll_response){ return }
                        if(poll?.status !== 'active'){ return }
                        if(draft_selected){ 
                            setPollData({ ...poll_data, selected_option: undefined }) 
                        } else { 
                            setPollData({ ...poll_data, selected_option: poll_option })
                        }
                    }}
                />
            </View>
        )
    }

    if(!poll){ return <></> }
    
    return (
        <View nativeID='poll' style={{ ...view_styles.section, minHeight:500 }} onLayout={(ev) => {
            const { width } = ev.nativeEvent.layout
            setPollWidth(width)
        }}>
            <View style={{ ...view_styles.section_header, backgroundColor:Colors.shades.shade100 }}>
                <View style={{ flex:1 }}>
                    <Text theme='header_2'>Question {poll.priority}</Text>
                    <Text style={{ marginTop:5 }} theme='header'>{poll.poll_question}</Text>
                </View>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View nativeID='question_image'>
                    {poll?.poll_image?.url ?
                    <Image
                        source={{ uri:poll.poll_image.url, width:poll_width, height: poll_width*0.5 }}
                        resizeMode='contain'
                    />
                    :<></>}
                </View>

            { poll.status == 'active' && !poll_response ?
            <View nativeID='question_respond_options'>
                <FlatList
                    data={poll_options.sort((a,b) => a.priority - b.priority)}
                    renderItem={renderPollOptions}
                    numColumns={2}
                    keyExtractor={(item) => item.poll_option_id.toString()}
                />
            </View>
            :
            <View nativeID='question_answer' style={{ flex:1 }}>
                {selected_option ?
                <View nativeID='selected_option' style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>MY SELECTED ANSWER</Text>
                    <PollOptionCard
                        show_result={true}
                        show_summary={false}
                        poll_response={poll_response}
                        onSelect={() => console.log('Hey')}
                        poll_option={selected_option}
                    />
                </View>
                : (!poll_response || poll_response?.timed_out) ?
                <View style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>MY SELECTED ANSWER</Text>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, borderWidth:1, borderColor:Colors.utility.error }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.utility.error} textAlign='center' weight='semibold'>Timed Out</Text>
                        <Icons.CloseIcon color={Colors.utility.error} size={12}/>
                    </View>
                </View>
                :<></>}
                {poll.poll_type == 'select' && correct_option ? 
                <View nativeID='selected_option' style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>CORRECT ANSWER</Text>
                    <PollOptionCard
                        show_summary={true}
                        show_result={true}
                        onSelect={() => console.log('')}
                        poll_option={correct_option}
                    />
                </View>
                :poll.poll_type == 'input' && poll.winning_value ?
                <View style={{ marginBottom:10, padding:10 }}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.slate} weight='semibold'>CORRECT ANSWER</Text>
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, borderRadius:22, borderWidth:1, borderColor:Colors.utility.success }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.utility.success} textAlign='center' weight='semibold'>{poll.winning_value}</Text>
                        <Icons.CheckIcon color={Colors.utility.success} size={12}/>
                    </View>
                </View>
                :<></>}
                {poll.poll_type == 'select' ?
                <View nativeID='option_results' style={{ backgroundColor:Colors.shades.shade100, padding:10, borderRadius:8 }}>
                    <View style={{ flexDirection:'row', alignItems:'center', marginBottom:5 }}>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.slate} weight='semibold'>PUBLIC RESULTS</Text>
                        <View style={{ borderRadius:22, padding:10, backgroundColor:Colors.accents.accent100 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='regular'>{responses} Responses</Text>
                        </View>
                    </View>
                    {!poll.show_responses ?
                    <Text style={{ marginBottom:5 }} color={Colors.utility.warning} size={12} >Responses are hidden until closed</Text>
                    :<></>}
                    <FlatList
                        data={poll_options}
                        renderItem={renderPollOptions}
                        keyExtractor={(item) => item.poll_option_id.toString()}
                    />
                </View>
                :<></>}
            </View>
            }
            </ScrollView>
            {!loading ?
            <View nativeID='question_actions' style={{ ...view_styles.section_footer, alignItems:undefined }}>
                {!poll.seconds_allowed || poll_response ?
                <TouchableOpacity
                    style={{ marginRight:10, justifyContent:'center', paddingRight:20, paddingLeft:20, backgroundColor:Colors.utility.warning, borderRadius:22, borderWidth:1, borderColor:Colors.utility.warning }}
                    onPress={() => handleCancel()}
                >
                    <Icons.ListIcon size={18} color={Colors.shades.white} />
                </TouchableOpacity>
                :<></>}
                {poll.status == 'active' && poll.seconds_allowed && !poll_response ?
                    <View style={{ marginRight:5 }}>
                        <ResponseTimer
                            poll_id={poll.poll_id}
                            seconds={poll.seconds_allowed}
                            onTimesUp={() => handleTimesUp()}
                        />
                    </View>
                :
                <Button
                    style={{flex:1, marginRight:5}}
                    title_color={Colors.brand.electric}
                    title={poll_response?'Next':'Skip'}
                    borderColor={Colors.brand.electric}
                    backgroundColor={Colors.shades.white}
                    borderRadius={22}
                    borderWidth={1}
                    padding={14}
                    onPress={() => handleSkip()}
                />
                }
                {!poll_response ?
                <Button
                    style={{flex:2}}
                    title_color={Colors.shades.white}
                    disabled={selected_option&&!action_loading?false:true}
                    title={selected_option?`Submit!`:poll.status == 'active' ? 'Select Answer' : 'Not Active'}
                    backgroundColor={selected_option&&!action_loading?Colors.utility.success:Colors.brand.slate}
                    borderRadius={22}
                    onPress={() => handleSubmitResponse()}
                />
                :<></>}
            </View>
            :<></>}
        </View>
        
    )
}

export default PollCard